/**
 * 로그인 권한이 필요한 페이지 접근 제어
 */
export default defineNuxtRouteMiddleware(() => {
  const { cookieNames, getCookie } = useCookies()
  let isAuth = false

  if (process.server) {
    const requestEvent = useRequestEvent()
    const token = requestEvent?.node.req.headers.cookie
      ? getCookie(cookieNames.AUTH_TOKEN, requestEvent.node.req.headers.cookie)
      : null

    isAuth = token !== undefined && token !== null
  } else {
    const userStore = useUserStore()
    isAuth = userStore.isSignIn
  }

  if (!isAuth) {
    throw showError({ statusCode: 403, statusMessage: 'Unauthorized' })
  }
})
